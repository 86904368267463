export interface Dictionary {
  [key: string]: string | Dictionary;
}

export enum LanguageKey {
  de = 'de',
  en = 'en'
}

export class TranslateService {

  private static instance: TranslateService;
  private language: string;
  private dictionaries: Record<string, Dictionary> = {};

  private constructor() {
    // empty to make sure getInstance() is used
  }

  public static getInstance(): TranslateService {
    return this.instance || (this.instance = new this());
  }

  static sanitizeText(text: string, strict?: boolean): string {
    if (!text) {
      return '';
    }
    const regExp = (strict) ? /[<>#&;]/g : /[<>]/g;
    return text.replace(regExp, '');
  }

  private static isValidLanguageKey(lang: LanguageKey) {
    return Object.values(LanguageKey).includes(lang);
  }

  /**
   * Sets the language to use.
   *
   * @param lang A language key
   */
  public setLanguage(lang: LanguageKey): void {
    if (!TranslateService.isValidLanguageKey(lang)) {
      throw new Error('Language key invalid');
    }
    this.language = lang;
  }

  /**
   * Return the currently used language.
   */
  public getLanguage(): string {
    return this.language;
  }

  /**
   * Registers a dictionary to use for the specified language.
   *
   * @param dictionary The dictionary to use.
   * @param lang The language to set the dictionary for.
   */
  public registerDictionary(dictionary: Dictionary, lang: LanguageKey): void {
    if (!TranslateService.isValidLanguageKey(lang)) {
      throw new Error('Language key invalid');
    }
    this.dictionaries[lang] = Object.freeze(dictionary);
  }

  /**
   * Returns the dictionary for the specified language.
   *
   * @param lang The language to get the dictionary of.
   */
  public getDictionary(lang: string): Dictionary {
    return this.dictionaries[lang];
  }

  /**
   * Translates the specified key by looking up the dictionary of the set language.
   * You may specify params to use in the translation as well.
   *
   * Use dot notation to reference nested keys, e.g. 'foo.bar.baz' (max nesting = 10).
   *
   * Example
   *
   * Dictionary (e.g., en.json):
   * ```
   * {
   *   "colored_animal": "{{animal}}: A {{color}} big {{animal}}."
   * }
   * ```
   *
   * In your code:
   * ```
   * const tr = TranslateService.getInstance();
   * console.log(tr.translate('colored_animal', {color: 'white', animal: 'cat'}) // Outputs "cat: A white big cat."
   * ```
   *
   * @param key The lookup key in the json dictionary.
   * @param params A set of params, the name specifying the name of the param in the dictionary.
   */
  public translate(key: string, params?: { [name: string]: string | number }): string {
    if (!this.language) {
      throw new Error('Language key missing');
    }
    const dictionary = this.dictionaries[this.language];
    if (!dictionary) {
      throw new Error('Dictionary missing');
    }

    if (key) {
      const nestedKeys = key.split('.');
      let rawValue = dictionary[nestedKeys[0]];
      const nestingCount = (nestedKeys.length > 10) ? 10 : nestedKeys.length; // max nesting

      let i = 1;
      while (rawValue && i < nestingCount) {
        rawValue = rawValue[nestedKeys[i]];
        i++;
      }

      if (typeof rawValue === 'string') {
        let translationResult: string = rawValue;
        if (params) {
          Object.entries(params).forEach(([name, value]) => {
            translationResult = translationResult.split(`\{\{${name}\}\}`)
              .join(TranslateService.sanitizeText(value.toString()));
          });
        }
        return translationResult;
      }
    }
    return key;
  }

}

/**
 * Convenience shorthand function for TranslateService.getInstance().translate()
 *
 * @param key The lookup key in the json dictionary.
 * @param params A set of params, the name specifying the name of the param in the dictionary.
 */
export function translate(key: string, params?: { [name: string]: string | number }): string {
  return TranslateService.getInstance().translate(key, params);
}
